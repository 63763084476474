@import '~app/mixins';


$buttonSize: 34px;

.button {
  background: $color-white;
  border: 1px solid $color-filter-border;
  position: absolute;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  transition: all $transition-delay;

  &.active:not([disabled]),
  &:hover:not([disabled]) {
    background: $color-light-blue;

    @include primary-border-color();
  }

  &[disabled] {
    background: lighten($color-grey, 45%);
    pointer-events: none;
  }

  &.drawButton {
    left: 10px;
    top: 10px;
    width: $buttonSize + 2;
    height: $buttonSize + 2;

    .iconMapDraw {
      width: $buttonSize;
      height: $buttonSize;
    }
  }
}

.draw {
  position: absolute;
  left: 50%;
  top: 10px;
  z-index: 1;
  transform: translateX(-50%);
  height: 44px;
  width: 600px;
  padding: 10px;
  border: 2px solid transparent;
  background-color: $color-white;
  box-shadow: $dropdown-shadow;

  @include primary-color();

  .drawInner {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 100%;

    .text {
      color: $color-grey;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

:global {
  .gm-fullscreen-control {
    //display: none;
  }
}
